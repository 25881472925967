$primary-color: #007bff;
$font-family: Arial, sans-serif;
$base-font-size: 16px;

$color-black: #000;
$color-white: #FFFFFF;
$color-active-tab-button: white;

$breakpoints-md: 767.95px;
$breakpoints-lg: 768px;
$breakpoints-xl: 992px;
$breakpoints-xxl: 1200px;
$breakpoints-xxl1200: 1200px;
$breakpoints-xxl1366: 1366px;
$breakpoints-xxl1600: 1600px;